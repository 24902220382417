import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {
    Box,
    Button,
    FormHelperText,
    TextField,
    Typography,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    Grid,
    Checkbox,
    Link,
    Card,
} from '@mui/material';
import { Alert } from '@mui/material';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import axios from "../../../utils/axios";

function ContactDetails({
                            enquiry,
                            stepName,
                            enquiryType,
                            onBack,
                            onNext,
                            onChangeEnquiry
                        }) {
    const [isAlertVisible, setAlertVisible] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const {executeRecaptcha} = useGoogleReCaptcha();

    const validationShape = {
        firstName: Yup.string().min(2, 'Must be at least 2 characters').max(200).required('Required'),
        lastName: Yup.string().min(2, 'Must be at least 2 characters').max(200).required('Required'),
        email: Yup.string().email().max(200).required('Required'),
        phone: Yup.string().trim().min(8, 'Must be at least 8 characters').max(20, 'Must be at less than or equal 20 characters').required('Required').matches(/^[0-9 ]+$/ , 'Can only contains numbers and spaces'),
        acceptTerms: Yup.boolean().oneOf([true], 'This field must be checked')
    };

    return (
        <Formik
            initialValues={enquiry}
            validationSchema={Yup.object().shape(validationShape)}
            onSubmit={(values, {
                resetForm,
                setErrors,
                setStatus,
                setSubmitting
            }) => {
                const token = executeRecaptcha("contact_details");
                token.then(result => {
                    values.recaptchaToken = result;

                    axios.post('/web/ui/bulkUser/createBulkUserUpdate', values).then(response => {
                        if(response.data) {
                            values.bulkUserUpdateId = response.data;
                            setStatus({success: true});
                            onChangeEnquiry(values);
                            if (onNext) {
                                onNext();
                            }
                        } else {
                            setErrors({submit: 'Error contact details'});
                            setStatus({success: false});
                            //setSubmitting(false);
                        }
                    }).catch(error => {
                        if(error.response && error.response.data) {
                            setErrors({submit: error.response.data.message});
                        } else {
                            setErrors({submit: error.message});
                        }
                        setAlertVisible(true);
                        setStatus({success: false});
                        setSubmitting(false);
                    });
                }).catch(error => {
                    setErrors({submit: error.message});
                    setAlertVisible(true);
                    setStatus({success: false});
                    setSubmitting(false);
                });
            }}
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  setFieldTouched,
                  touched,
                  values
              }) => (
                <Card>
                    <CardHeader title={enquiryType.title + " - " + stepName} titleTypographyProps={{variant: 'h5', color: 'secondary'}}/>
                    <Divider/>
                    <CardContent>
                        {errors.submit && isAlertVisible && (
                            <Box mb={3}>
                                <Alert
                                    onClose={() => setAlertVisible(false)}
                                    severity="info"
                                >
                                    {errors.submit}
                                </Alert>
                            </Box>
                        )}
                        {isSubmitting || isLoading ? (
                            <Box
                                display="flex"
                                justifyContent="center"
                                my={5}
                            >
                                <CircularProgress/>
                            </Box>
                        ) : (
                            <form onSubmit={handleSubmit}>
                                <Box mt={2}>
                                    <Typography
                                        variant="subtitle1"
                                        color="textSecondary"
                                    >
                                        Please provide us your details so that we can contact you after your request is complete.
                                    </Typography>
                                </Box>
                                <Box mt={2}>
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <TextField
                                                error={Boolean(touched.firstName && errors.firstName)}
                                                fullWidth
                                                helperText={touched.firstName && errors.firstName}
                                                label="First Name"
                                                name="firstName"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.firstName}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <TextField
                                                error={Boolean(touched.lastName && errors.lastName)}
                                                fullWidth
                                                helperText={touched.lastName && errors.lastName}
                                                label="Last Name"
                                                name="lastName"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.lastName}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box mt={2}>
                                    <TextField
                                        error={Boolean(touched.email && errors.email)}
                                        fullWidth
                                        helperText={touched.email && errors.email}
                                        label="Email"
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="email"
                                        value={values.email}
                                        variant="outlined"
                                    />
                                </Box>
                                <Box mt={2}>
                                    <TextField
                                        error={Boolean(touched.phone && errors.phone)}
                                        fullWidth
                                        helperText={touched.phone && errors.phone}
                                        label="Phone"
                                        name="phone"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.phone}
                                        variant="outlined"
                                    />
                                </Box>
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    mt={2}
                                    ml={-1}
                                >
                                    <Checkbox
                                        checked={values.acceptTerms}
                                        name="acceptTerms"
                                        onChange={handleChange}
                                    />
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        I have read the
                                        {' '}
                                        <Link
                                            component="a"
                                            href="https://www.cambridge.org/legal/website-terms-of-use"
                                            color="secondary"
                                            target="_blank"
                                        >
                                            Terms and Conditions
                                        </Link>
                                    </Typography>
                                </Box>
                                {Boolean(touched.acceptTerms && errors.acceptTerms) && (
                                    <FormHelperText error>
                                        {errors.acceptTerms}
                                    </FormHelperText>
                                )}
                                <Box
                                    mt={6}
                                    display="flex"
                                >
                                    {onBack && (
                                        <Button
                                            onClick={() => {setLoading(true); onBack();}}
                                            size="large"
                                            color="secondary"
                                            variant="contained"
                                        >
                                            Previous
                                        </Button>
                                    )}
                                    <Box flexGrow={1}/>
                                    <Button
                                        color="secondary"
                                        disabled={isSubmitting}
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                    >
                                        Next
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </CardContent>
                </Card>
            )}
        </Formik>

    );
}

ContactDetails.propTypes = {
    enquiryType: PropTypes.object,
    stepName: PropTypes.string,
    enquiry: PropTypes.object,
    onNext: PropTypes.func,
    onBack: PropTypes.func,
    onChangeEnquiry: PropTypes.func
};

export default ContactDetails;
